<template>
  <el-container id="box" style="height: 100vh">
    <!--<el-container>-->
      <el-aside id="leftNav" width="256">
        <Nav/>
      </el-aside>

      <!--<el-container style="display: flex;flex-direction: column">-->
      <el-container>
        <el-header id="header">
          <Header/>
        </el-header>
        <el-main id="main">
            <router-view :key="key" />
        </el-main>
      </el-container>
    <!--</el-container>-->
  </el-container>
</template>

<script>
import Nav from "./Nav.vue";
import Header from "./Header.vue";
// import autofit from "autofit.js"
export default {
  name: "Layout",
  components: {Header, Nav},
  computed: {
    key() {
      return this.$route.fullPath
    }
  },
  mounted(){
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#app",
        resize: true
      },
      true
    )
  }
}
</script>

<style scoped>
#header {
  background: #ffffff;
  line-height: 60px;
  //padding: 0;
}

/*#leftNav {
  min-height: 1080px;
}*/

#box {
  background: #f5f8fe;
}
#main{
  padding: 0px 24px 0px 24px;
  //height: 90vh
}
</style>
