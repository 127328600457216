<template>
  <div id="content">
    <div v-if="!addFlag" style="padding-top: 20px">
      <el-row :gutter="24" style="display: flex;flex-flow: wrap">
        <el-col :span="6" style="display: flex;text-align: center;align-items: center">
          <span class="searchText">代理编号：</span>
          <el-input placeholder="请输入代理商编号或代理商名称" v-model="searchData.agentId" size="mini"
                    style="width: 230px" clearable/>
        </el-col>
        <el-col :span="6" style="display: flex;text-align: center;align-items: center">
          <span class="searchText">应用号：</span>
          <el-input placeholder="请输入应用号或应用名称" v-model="searchData.mchId" size="mini"
                    style="width: 230px" clearable/>
        </el-col>
        <el-col :span="5" style="display: flex;text-align: center;align-items: center">
          <span class="searchText">手机号：</span>
          <el-input placeholder="请输入手机号" v-model="searchData.phone" size="mini"
                    style="width: 230px" clearable/>
        </el-col>
        <el-col :span="5" style="display: flex;text-align: center;align-items: center">
          <span class="searchText">联系人：</span>
          <el-input placeholder="请输入联系人姓名" v-model="searchData.name" size="mini"
                    style="width: 230px" clearable/>
        </el-col>
        <el-col :span="7"
                style="margin-top: 20px;display: flex;text-align: center;align-items: center">
          <span class="searchText">时间筛选：</span>
          <el-date-picker
              size="mini"
              style="width: 230px;"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="searchData.date"
              type="datetimerange"
              align="right"
              @change=""
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row style="margin-left: 50px;margin-top: 20px;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="initData">查询</el-button>
        <el-button type="default" icon="el-icon-refresh-right" size="mini" @click="queryRest">重置</el-button>
      </el-row>
      <el-divider/>
      <HssTable :table-header="tableHeader" :table-data="list" :loading="loading">
        <template #agentName="{row}">
          <div style="display: flex;flex-direction: column;">
            <span style="color: #000000;font-weight: 800;">{{ row.agentName }}</span>
            <span style="color: #135AFA;font-size: 12px;font-weight: bold">
              {{ row.agentId }}
              <i class="el-icon-document-copy" style="cursor: pointer;margin-left: 4px;"
                 @click="copyText(row.agentId)"></i>
            </span>
          </div>
        </template>
        <template #mchId="{row}">
          <div style="display: flex;flex-direction: column;">
            <span style="color: #000000;font-weight: 800;">{{ row.mname }}</span>
            <span style="color: #135AFA;font-size: 12px;font-weight: bold">
              {{ row.mchId }}
              <i class="el-icon-document-copy" style="cursor: pointer;margin-left: 4px;"
                 @click="copyText(row.mchId)"></i>
            </span>
          </div>
        </template>
        <template #pid="{row}">
          <div style="display: flex;flex-direction: column;">
            <span style="color: #000000;font-weight: 800;">{{ row.mchFor }}</span>
            <span style="color: #135AFA;font-size: 12px;font-weight: bold">
              {{ row.id }}
              <i class="el-icon-document-copy" style="cursor: pointer;margin-left: 4px;" @click="copyText(row.id)"></i>
            </span>
          </div>
        </template>
        <template #type="{row}">
          <el-tag size="mini" effect="dark" type="info" v-if="row.incomType === 1">小微</el-tag>
          <div v-else>
            <el-tag size="mini" effect="dark" type="primary" v-if="row.aptitude === 0">个体</el-tag>
            <el-tag size="mini" effect="dark" type="success" v-else>企业</el-tag>
          </div>
        </template>
        <template #name="{row}">
          <span style="font-size: 12px;">{{row.userName}}</span>
        </template>
        <template #status="{row}">
          <el-tag size="mini" v-if="row.incomStatus === -1">草稿</el-tag>
          <el-tag size="mini" type="primary" v-if="row.incomStatus === 0">待审核</el-tag>
          <el-tag size="mini" effect="plain" type="warning" v-if="row.incomStatus === 1">审核中</el-tag>
          <el-tag size="mini" effect="plain" type="error" v-if="row.incomStatus === 2">进件成功</el-tag>
          <el-tag size="mini" effect="plain" type="error" v-if="row.incomStatus === 3">审核失败</el-tag>
        </template>
        <template #createTime="{row}">
          <span>{{row.createTime.replaceAll("T"," ")}}</span>
        </template>
        <template #operation="{row}">
          <el-button type="text" @click="addPiece2(row)" style="font-size: 12px;" icon="el-icon-s-check">去审核</el-button>
        </template>
      </HssTable>
    </div>
    <ViewPiece :form-value="parentValue" v-if="addEditFlag"></ViewPiece>
    <shengfutong-add-tenant  :form-value="parentValueShengfutong" v-if="shengfutongAddFlag"></shengfutong-add-tenant>
  </div>
</template>

<script>
import HssTable from "@/components/HssTable.vue";
import {pieceList} from "@/api/piece/piece";
import {initData} from "@/api/user/user";
import ViewPiece from "@/views/mch/component/ViewPiece.vue";
import ShengfutongAddTenant from "@/views/sft/shengfutongAddTenant.vue";
export default {
  name: "InputPieceSh",
  components: {HssTable,ViewPiece,ShengfutongAddTenant},
  data() {
    return {
      addEditFlag:false,
      pageSize: 10,
      pageNum: 1,
      total: 0,
      list: [],
      loading: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tableHeader: [
        {
          label: '所属代理商',
          prop: 'agentName',
          scopeStatus: true
        },
        {
          label: '所属应用',
          prop: 'mchId',
          scopeStatus: true
        },
        {
          label: '所属商户',
          prop: 'pid',
          scopeStatus: true
        },
        {
          label: '商户类型',
          prop: 'type',
          scopeStatus: true
        },
        {
          label: '商户名称',
          prop: 'name',
          scopeStatus: true
        },
        {
          label: '进件状态',
          prop: 'status',
          scopeStatus: true
        },
        {
          label: '创建日期',
          prop: 'createTime',
          scopeStatus: true
        },
        {
          label: '操作',
          prop: 'operation',
          scopeStatus: true
        }
      ],
      parentValue: {},
      parentValueShengfutong:{},
      addFlag: false,
      shengfutongAddFlag: false,
      searchData: {
        mchId: '',
        date: '',
        phone: '',
        name: ''
      }
    }
  },
  mounted() {
    this.initData()
    this.initCategory()
  },
  methods: {
    changeType() {
      this.addFlag = false;
      this.addPieceFlag = false;
      this.addEditFlag=false;
      this.shengfutongAddFlag = false;
      this.initData()
    },
    initCategory() {
      initData().then(res => {
        if (res.success) {
          if (!localStorage.getItem("initData")) {
            localStorage.setItem("initData", JSON.stringify(res.data))
          }
        }
      })
    },
    addPiece2(row) {
      // console.log(row);
      if(12 === row.tenantId){
        // 跳转到盛付通页面
        let json = JSON.parse(JSON.stringify(row));
        json.operation = 'audit';
        this.parentValueShengfutong = json;
        // 查询页面
        this.addFlag = true;
        // 其他通道进件编辑页面
        this.addEditFlag = false;
        // 盛付通页面
        this.shengfutongAddFlag = true;
      }else{
        this.parentValue = row;
        this.addFlag = true;
        this.addEditFlag = true;
        this.shengfutongAddFlag = false;
      }
    },
    queryRest() {
      this.searchData = {
        mchId: '',
        date: '',
        phone: '',
        name: ''
      }
      this.initData()
    },
    copyText(content) {
      navigator.clipboard.writeText(content)
          .then(() => {
            this.$message.success('复制成功')
          })
          .catch(() => {
            this.$message.error('复制失败')
          })
    },
    initData() {
      this.loading = true;
      let param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: this.searchData.name || null,
        agentId: this.$store.getters.userInfo.userName === 'admin' ? null : this.searchData.agentId || this.$store.getters.userInfo.id,
        status: 0
      }
      pieceList(param).then(res => {
        if (res.success) {
          this.loading = false;
          this.total = res.data.total
          this.list = res.data.list;
        }
      })
    }
  }
}
</script>

<style scoped>
#content {
  width: 100%;
  background: #ffffff;
  min-height: 830px;
  margin: 16px 16px 0 0;
  font-family: diy-font;
}

.searchText {
  font-size: 12px;
  margin-left: 20px;
  font-weight: bold;
  width: 120px;
}

/deep/ .el-divider--horizontal {
  height: 0;
  border: 1px solid #f4f6f9;
  margin-bottom: 10px;
}
</style>
