// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import * as filters from './filters'
import './assets/element-variables.scss'
import '../src/assets/font/font_4355151_67cd1pbrlwi/iconfont.css'
import '@/scss/index.scss'
import '@/scss/font-test.css'
Vue.config.productionTip = false
Vue.use(ElementUI)
Object.keys(filters).filter(key =>{
  Vue.filter(key,filters[key])
})
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});
// router.afterEach((to, from, next) => {
//   document.querySelector("body").setAttribute("style", "overflow: auto !important;")
// });

